<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">专辑配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label" v-if="xForm.name[lang] && xForm.description[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-col :span="23">
                <el-form-item label="场景名">
                  <el-input v-model="xForm.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述">
                  <el-input type="textarea" :rows="4" v-model="xForm.description[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="时长描述(如果是单章节，客户端会展示该时长描述)">
                  <el-input v-model="xForm.duration_description[lang]"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="23" v-if="lang === 'zh-Hant'">
                <el-form-item>
                  <el-button type="text" @click="s2t4album">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-row>
          <el-form-item label="快速填充">
            <el-input type="textarea" :rows="2" v-model="shortcut" placeholder="中文名, 中文描述, 英文名, 英文描述, 中文时长描述, 英文时长描述, 主颜色"></el-input>
          </el-form-item>
        </el-row>

        <el-form-item label="主色调：">
          <color-picker v-model="xForm.primary_color" style="width: 50%"></color-picker>
        </el-form-item>

        <el-form-item label="封面图片">
          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">如果存在视频封面，那么该自动截取首帧生成</div>
              <span>封面</span>
            </el-tooltip>
            <image-uploader v-model="xForm.image" :resourceFolder="this.resourceFolder"></image-uploader>
          </el-col>

          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">上传后会自动裁切出首帧和 Demo 视频</div>
              <span>视频</span>
            </el-tooltip>
            <encrypt-video-uploader :hash-key="xForm.video_cover_key" :resourceFolder="this.resourceFolder" :persistentOps="meditationCoverVideoUploadPersistentOps()" @change="meditationCoverVideoHashChanged"></encrypt-video-uploader>
          </el-col>

          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">等同于正式视频</div>
              <span>DEMO 视频(自动生成)</span>
            </el-tooltip>
            <video-uploader v-model="xForm.video_cover_demo_url" :resourceFolder="this.resourceFolder"></video-uploader>
          </el-col>
        </el-form-item>

        <el-form-item label="展示样式：">
          <el-radio-group v-model="xForm.style.size">
            <el-radio label="small">小图</el-radio>
            <el-radio label="big">大图</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="练习要求：">
          <el-radio-group v-model="xForm.require_continuous">
            <el-radio :label="true">按顺序练习</el-radio>
            <el-radio :label="false">无顺序要求</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否有序：">
          <el-radio-group v-model="xForm.is_orderly">
            <el-radio :label="true">有序</el-radio>
            <el-radio :label="false">无序</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否轻冥想：">
          <el-radio-group v-model="xForm.style.show_detail_page">
            <el-radio :label="false">是</el-radio>
            <el-radio :label="true">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="推荐导师">
          <participants-selecter v-model="xForm.speaker_id_preference" style="width: 60%"></participants-selecter>
        </el-form-item>

        <el-form-item label="加入标签[手动]">
          <el-col>
            <el-select v-model="xForm.manual_simple_tag_ids" value-key="id" multiple filterable default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="加入标签[自动]">
          <el-col>
            <el-select v-model="xForm.simple_tag_ids" value-key="id" multiple disabled filterable default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="公告提醒">
          <el-col>
            <el-select v-model="xForm.notices" value-key="id" multiple filterable default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="技巧列表">
          <el-col>
            <el-select v-model="xForm.related_skill_ids" multiple filterable placeholder="选择技巧" style="width: 100%">
              <el-option v-for="item in skillOptions" :key="item.value.id" :label="getTR(item.value.name)" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="相关专辑列表">
          <el-col>
            <el-select v-model="xForm.related_album_ids" multiple filterable placeholder="选择专辑" style="width: 100%">
              <el-option v-for="item in albumOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + getStatus(item.value.status)" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="相关文章列表">
          <el-col>
            <el-select v-model="xForm.related_article_ids" multiple filterable placeholder="选择文章" style="width: 100%">
              <el-option v-for="item in articleOptions" :key="item.value.id" :label="getTR(item.value.title) + ' | ' + getStatus(item.value.status)" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="搜索关键字">
          <el-col>
            <el-select v-model="xForm.search_annotations" value-key="id" multiple filterable default-first-option placeholder="选择关键字" style="width: 100%">
              <el-option v-for="item in annotationOptions" :key="item.value.id" :label="item.value.name" :value="item.value"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="上架区域：">
          <regions-selecter v-model="xForm.regions" style="width: 60%"></regions-selecter>
        </el-form-item>
        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages" style="width: 60%"></languages-selecter>
        </el-form-item>
        <el-form-item label="展示省份" v-if="xForm.regions.length === 1 && xForm.regions[0] === 'CN'">
          <provinces-selecter v-model="xForm.provinces" style="width: 60%"></provinces-selecter>
        </el-form-item>
        <el-form-item label="上架渠道：">
          <channels-selector v-model="xForm.channels" style="width: 60%"></channels-selector>
        </el-form-item>
        <el-form-item label="禁止上架渠道：">
          <channels-selector v-model="xForm.blocked_channels" style="width: 60%"></channels-selector>
        </el-form-item>

        <el-form-item label="iOS上架版本：">
          <el-input-tag v-model="xForm.ios_versions" style="width: 60%"></el-input-tag>
        </el-form-item>
        <el-form-item label="Android上架版本：">
          <el-input-tag v-model="xForm.android_versions" style="width: 60%"></el-input-tag>
        </el-form-item>

        <el-form-item label="上架状态：">
          <status-radio v-model="xForm.status" style="width: 60%"></status-radio>
        </el-form-item>

        <el-form-item label="新品角标：">
          <el-radio-group v-model="freshMark">
            <el-radio :label="false">不显示</el-radio>
            <el-radio :label="true">显示到</el-radio>
          </el-radio-group>
          <el-date-picker type="datetime" style="margin-left: 8px" size="mini" :disabled="!freshMark" v-model="freshUntil"></el-date-picker>
        </el-form-item>

        <el-form-item label="限时体验选项">
          <el-radio-group v-model="triallLock">
            <el-radio :label="true">不限时</el-radio>
            <el-radio :label="false">限时</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="限时体验时间">
          <el-date-picker :disabled="triallLock" size="medium" type="datetime" v-model="trialStartedAt" placeholder="开始时间" default-time="23:59:59"></el-date-picker>
          <span> 到 </span>
          <el-date-picker :disabled="triallLock" size="medium" type="datetime" v-model="trialEndedAt" placeholder="结束时间" default-time="23:59:59"></el-date-picker>
        </el-form-item>

        <el-form-item label="限时体验登录状态">
          <login-status-selecter v-model="xForm.trial_login_status" style="width: 60%"></login-status-selecter>
        </el-form-item>
      </el-form>

      <div class="container-head">
        <div class="container-head-title">章节列表</div>
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="dialogDisplay.sortPanel = true" type="text">重新排序</el-button>
          <el-button :disabled="!$store.getters.hasEditRole" @click="addSection" type="text">新增章节</el-button>
        </div>
      </div>

      <div class="container-body">
        <el-table :data="xForm.sections" style="width: 100%">
          <el-table-column label="排序" width="100px" align="left">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="章节图片[无则使用专辑图片]">
            <template slot-scope="scope">
              <cover :width="48" :height="48" :src="scope.row.image"></cover>
            </template>
          </el-table-column>

          <el-table-column label="章节名称">
            <template slot-scope="scope">{{ scope.row.name['zh-Hans'] || scope.row.name.en }}</template>
          </el-table-column>
          <el-table-column label="音频资源数">
            <template slot-scope="scope">{{ scope.row.resources_v2.length }}</template>
          </el-table-column>
          <el-table-column label="审阅资源数">
            <template slot-scope="scope">{{ scope.row.resources_v2.filter((resource) => resource.status === 'reviewing').length }}</template>
          </el-table-column>
          <el-table-column label="定价">
            <template slot-scope="scope">{{ scope.row.access === 'free' ? '免费' : scope.row.access === 'subscribe' ? '会员免费' : '' }}</template>
          </el-table-column>
          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="editSection(scope.$index)">编辑</el-button>
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="removeSection(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span>
        <el-dialog title="冥想章节排序" width="60%" :visible.sync="dialogDisplay.sortPanel">
          <draggable class="sort-list-container" v-model="xForm.sections">
            <div class="sort-item" v-for="(albumId, index) in xForm.sections" :key="index">
              <div class="sort-item-key">{{ index + 1 }}</div>
              <div class="sort-item-name">{{ getTR(xForm.sections[index].name) }}</div>
            </div>
          </draggable>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogDisplay.sortPanel = false">返 回</el-button>
          </span>
        </el-dialog>

        <el-dialog title="冥想章节编辑" :visible.sync="dialogDisplay.editSection" width="75%">
          <el-form v-if="activeSection">
            <el-tabs type="border-card" v-if="xForm.sections.length" v-model="activeSectionActiveLang">
              <el-tab-pane v-for="lang in langs" :key="lang" :name="lang">
                <span slot="label" v-if="activeSection.name[lang] && activeSection.description[lang] && activeSection.duration_description[lang]">{{ lang }}</span>
                <span slot="label" v-else>{{ lang }} (未填写完整)</span>
                <el-form-item label="章节名">
                  <el-input v-model="activeSection.name[lang]"></el-input>
                </el-form-item>

                <el-form-item label="时长描述">
                  <el-input v-model="activeSection.duration_description[lang]"></el-input>
                </el-form-item>

                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2t4section">文案简中转繁中</el-button>
                </el-form-item>
              </el-tab-pane>
            </el-tabs>
            <br />
            <br />
            <el-row>
              <el-col :span="24">
                <el-col :span="4">
                  <el-form-item label="章节封面图片">
                    <image-uploader v-model="activeSection.image" :resourceFolder="this.resourceFolder"></image-uploader>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>

            <el-form-item label="章节定价：" style="margin-top: 24px">
              <el-radio-group v-model="activeSection.access">
                <el-radio label="free">免费</el-radio>
                <el-radio label="subscribe">会员免费</el-radio>
              </el-radio-group>
            </el-form-item>

            <span>
              <div class="resources-header">
                <div class="resources-title">冥想认知资源列表</div>
                <div class="resources-actions">
                  <el-button type="text" @click="addCognitionResource">上传新资源</el-button>
                </div>
              </div>

              <el-table class="resources-content" v-if="activeSection" :data="activeSection.cognitions" :key="dialogDisplay.activeSectionCognitionsKeyFlag" stripe>
                <el-table-column label="资源名" width="300">
                  <template slot-scope="scope">
                    {{ scope.row.name }}<br />
                    {{ scope.row.guid }}
                  </template>
                </el-table-column>

                <el-table-column label="时长">
                  <template slot-scope="scope">{{ scope.row.duration }}分钟</template>
                </el-table-column>

                <el-table-column label="音频">
                  <template slot-scope="scope">{{ scope.row.audio_url ? '有' : '无' }}</template>
                </el-table-column>

                <el-table-column label="视频">
                  <template slot-scope="scope">{{ scope.row.video_url ? '有' : '无' }}</template>
                </el-table-column>

                <el-table-column label="图片">
                  <template slot-scope="scope">{{ scope.row.cover_url ? '有' : '无' }}</template>
                </el-table-column>

                <el-table-column label="朗读者">
                  <template slot-scope="scope"> {{ getTR(getParticiInfo(scope.row.speaker_id).name_i18n) }} [{{ getParticiInfo(scope.row.speaker_id).language }}] -- {{ scope.row.language }} </template>
                </el-table-column>

                <el-table-column label="状态">
                  <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
                </el-table-column>

                <el-table-column label="操作" align="right">
                  <template slot-scope="scope">
                    <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="editCognitionResource(scope.row.guid)">编辑</el-button>
                    <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="deleteCognitionResource(scope.row.guid)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </span>

            <span>
              <div class="resources-header">
                <div class="resources-title">冥想资源列表</div>
                <div class="resources-actions">
                  <el-button type="text" @click="addResource">上传新资源</el-button>
                </div>
              </div>

              <el-table class="resources-content" v-if="activeSection" :data="activeSection.resources_v2" :key="dialogDisplay.activeSectionKeyFlag" stripe>
                <el-table-column label="资源名" width="300">
                  <template slot-scope="scope">
                    {{ scope.row.name }}<br />
                    {{ scope.row.guid }}
                  </template>
                </el-table-column>

                <el-table-column label="时长">
                  <template slot-scope="scope"
                    >{{ scope.row.duration }}分钟 <b>{{ scope.row.is_default_duration ? '（默认）' : '' }}</b></template
                  >
                </el-table-column>

                <el-table-column label="白噪音">
                  <template slot-scope="scope">{{ scope.row.has_white_noise ? '是' : '否' }}</template>
                </el-table-column>

                <el-table-column label="人声">
                  <template slot-scope="scope">{{ scope.row.has_human_voice ? '是' : '否' }}</template>
                </el-table-column>

                <el-table-column label="朗读者">
                  <template slot-scope="scope"> {{ getTR(getParticiInfo(scope.row.speaker_id).name_i18n) }} [{{ getParticiInfo(scope.row.speaker_id).language }}] </template>
                </el-table-column>

                <el-table-column label="状态">
                  <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
                </el-table-column>

                <el-table-column label="操作" align="right">
                  <template slot-scope="scope">
                    <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="editResource(scope.row.guid)">编辑</el-button>
                    <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="deleteResource(scope.row.guid)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </span>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogDisplay.editSection = false">返 回</el-button>
          </div>
        </el-dialog>

        <el-dialog title="冥想资源编辑" :visible.sync="dialogDisplay.editResource" width="75%">
          <el-form v-if="activeResource">
            <el-input v-model="activeResource.guid" disabled></el-input>
            <el-form-item label="资源名(不要为空)">
              <el-input v-model="activeResource.name"></el-input>
            </el-form-item>

            <el-form-item label="音频">
              <encrypt-audio-uploader :hash-key="activeResource.hash_key" :resourceFolder="this.resourceFolder" @change="resourceHashChanged"></encrypt-audio-uploader>
            </el-form-item>

            <el-form-item label="人声音频">
              <encrypt-audio-uploader :hash-key="activeResource.human_voice_hash_key" :resourceFolder="this.resourceFolder" @change="resourceHumanHashChanged"></encrypt-audio-uploader>
            </el-form-item>

            <el-row>
              <el-form-item label="是否有静音选项">
                <el-radio-group v-model="activeResource.tail_has_mute_option">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item label="默认声音场景">
                <el-select v-model="activeResource.tail_scene_id" clearable filterable placeholder="选择声音场景" style="width: 30%">
                  <el-option v-for="item in sceneOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + getStatus(item.value.status) + ' | ' + item.value.category" :value="item.value.id"></el-option>
                </el-select>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item label="推荐声音场景">
                <el-select v-model="activeResource.tail_recommend_scene_ids" clearable multiple filterable placeholder="选择声音场景" style="width: 60%">
                  <el-option v-for="item in sceneOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + getStatus(item.value.status) + ' | ' + item.value.category" :value="item.value.id"></el-option>
                </el-select>
              </el-form-item>
            </el-row>

            <el-form-item label="时长">
              <el-input-number v-model="activeResource.duration"></el-input-number>
            </el-form-item>
            <el-form-item label="默认时长版本：">
              <el-radio-group v-model="activeResource.is_default_duration">
                <el-radio :label="false">否</el-radio>
                <el-radio :label="true">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="语言">
              <el-radio-group v-model="getParticiInfo(activeResource.speaker_id).language" size="mini" disabled>
                <el-radio-button label="zh">zh</el-radio-button>
                <el-radio-button label="en">en</el-radio-button>
                <el-radio-button label="zh_yue">zh_yue</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="朗读者">
              <span class="mini-speaker" v-if="activeResource.speaker_id">{{ getTR(getParticiInfo(activeResource.speaker_id).name_i18n) }}</span>
              <el-button type="text" size="small" @click="showChooseSpeaker">{{ activeResource.speaker_id ? '更换' : '选择' }}</el-button>
            </el-form-item>

            <el-row>
              <el-form-item label="白噪音">
                <el-radio-group v-model="activeResource.has_white_noise">
                  <el-radio :label="false">否</el-radio>
                  <el-radio :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item label="人声">
                <el-radio-group v-model="activeResource.has_human_voice">
                  <el-radio :label="false" disabled>否</el-radio>
                  <el-radio :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>

            <el-form-item label="状态：">
              <status-radio v-model="activeResource.status" style="width: 60%"></status-radio>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogDisplay.editResource = false">返 回</el-button>
          </div>
        </el-dialog>

        <el-dialog title="冥想认知资源编辑" :visible.sync="dialogDisplay.editCognitionResource" width="75%">
          <el-form v-if="activeCognitionResourceGuid">
            <el-input v-model="activeCognitionResource.guid" disabled></el-input>
            <el-form-item label="资源名(不要为空)">
              <el-input v-model="activeCognitionResource.name"></el-input>
            </el-form-item>
            <el-form-item label="时长">
              <el-input-number v-model="activeCognitionResource.duration"></el-input-number>
            </el-form-item>

            <el-form-item label="语言">
              <el-radio-group v-model="activeCognitionResource.language" size="mini" disabled>
                <el-radio-button label="zh">zh</el-radio-button>
                <el-radio-button label="en">en</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="朗读者">
              <span class="mini-speaker" v-if="activeCognitionResource.speaker_id">{{ getTR(getParticiInfo(activeCognitionResource.speaker_id).name_i18n) }}</span>
              <el-button type="text" size="small" @click="showChooseSpeaker">{{ activeCognitionResource.speaker_id ? '更换' : '选择' }}</el-button>
            </el-form-item>

            <el-form-item label="图片">
              <image-uploader v-model="activeCognitionResource.cover_url" :resourceFolder="this.resourceFolder"></image-uploader>
            </el-form-item>

            <el-form-item label="音频文件">
              <audio-uploader v-model="activeCognitionResource.audio_url" :resourceFolder="this.resourceFolder"></audio-uploader>
            </el-form-item>

            <el-form-item label="状态：">
              <status-radio v-model="activeCognitionResource.status" style="width: 60%"></status-radio>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button
              @click="
                dialogDisplay.editCognitionResource = false;
                activeCognitionResourceGuid = '';
              "
              >返 回</el-button
            >
          </div>
        </el-dialog>

        <el-dialog title="选择朗读者" :visible.sync="dialogDisplay.chooseSpeaker">
          <div class="speakers">
            <el-table ref="speakerTable" :data="allSpeakers" style="width: 100%" @current-change="onSelectSpeaker">
              <el-table-column label="名字">
                <template slot-scope="scope">
                  <div class="participant-info">
                    <cover v-if="scope.row.avatar.length" :width="48" :height="48" :src="scope.row.avatar"></cover>
                    {{ getTR(scope.row.name_i18n) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="语言">
                <template slot-scope="scope">{{ scope.row.language }}</template>
              </el-table-column>

              <el-table-column label="嘉宾">
                <template slot-scope="scope">{{ scope.row.special ? '是' : '否' }}</template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogDisplay.chooseSpeaker = false">返 回</el-button>
          </div>
        </el-dialog>
      </span>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Constant from '@/constant.json';
import Container from '@/components/container';
import ColorPicker from '@/components/color-picker';
import AudioUploader from '@/components/audio-uploader';
import ImageUploader from '@/components/image-uploader';
import VideoUploader from '@/components/video-uploader';
import Draggable from 'vuedraggable';
import Cover from '@/components/cover';
import RegionsSelecter from '@/components/regions-selecter';
import ChannelsSelector from '@/components/channels-selector';
import LanguagesSelecter from '@/components/languages-selecter';
import ProvincesSelecter from '@/components/provinces-selecter';
import OpenCC from '@/plugins/opencc';
import ElInputTag from '@/components/el-input-tag';
import { v4 as uuidv4 } from 'uuid';
import EncryptAudioUploader from '@/components/encrypt-audio-uploader.vue';
import EncryptVideoUploader from '@/components/encrypt-video-uploader';
import LoginStatusSelecter from '@/components/login-status-selecter';
import ParticipantsSelecter from '@/components/participants-selecter';
import StatusRadio from '@/components/status-radio';
import allStatus from '@/status.json';
import { qiniuUrlsafeBase64Encode } from '@/utils.js';

function hex2Rgb(hex, alpha) {
  var RGBA = parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + parseInt(alpha) / 100;
  return RGBA;
}

export default {
  name: 'AlbumForm',
  components: {
    Container,
    Draggable,
    Cover,
    ColorPicker,
    AudioUploader,
    ImageUploader,
    VideoUploader,
    RegionsSelecter,
    ProvincesSelecter,
    ChannelsSelector,
    LanguagesSelecter,
    ElInputTag,
    EncryptAudioUploader,
    EncryptVideoUploader,
    LoginStatusSelecter,
    ParticipantsSelecter,
    StatusRadio,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = this.$env;
    return {
      loading: false,
      env,
      langs: require('@/langs.json'),

      objectTagOptions: [],
      simpleTagOptions: [],
      skillOptions: [],
      albumOptions: [],
      articleOptions: [],
      allSpeakers: [],
      allParticipants: [],
      annotationOptions: [],
      sceneOptions: [],
      resourceToken: '',
      picToken: '',
      picCDN: '',
      shortcut: '',
      speakers: [],

      activeSectionIndex: 0,
      activeSectionActiveLang: 'en',
      activeResourceGuid: '',
      activeCognitionResourceGuid: '',

      dialogDisplay: {
        editSection: false,
        sortPanel: false,
        editResource: false,
        editCognitionResource: false,

        activeSectionCognitionsKeyFlag: 0,
        activeSectionKeyFlag: 0,

        chooseSpeaker: false,
      },

      resourceFolder: 'meditation',
    };
  },
  watch: {
    shortcut(val) {
      const quoteInfos = val.split('	');
      console.log(quoteInfos.length);
      if (quoteInfos.length !== 7) {
        this.$message.error('解析失败，无法填充');
      } else {
        const [zh_hans_name, zh_hans_description, en_name, en_description, zh_hans_duration_description, en_duration_description, primary_color] = quoteInfos;
        this.$nextTick(() => {
          this.form.name['zh-Hans'] = zh_hans_name;
          this.form.name.en = en_name;
          this.form.name.es = en_name;
          this.form.name.ru = en_name;
          this.form.name.ko = en_name;
          this.form.name.ja = en_name;

          this.form.description['zh-Hans'] = zh_hans_description;
          this.form.description.en = en_description;
          this.form.description.es = en_description;
          this.form.description.ru = en_description;
          this.form.description.ko = en_description;
          this.form.description.ja = en_description;

          this.form.duration_description['zh-Hans'] = zh_hans_duration_description ? `${zh_hans_duration_description} 分钟` : '';
          this.form.duration_description.en = en_duration_description ? `${en_duration_description} MIN` : '';
          this.form.duration_description.es = en_duration_description ? `${en_duration_description} MIN` : '';
          this.form.duration_description.ru = en_duration_description ? `${en_duration_description} мин` : '';
          this.form.duration_description.ko = en_duration_description ? `${en_duration_description} 분` : '';
          this.form.duration_description.ja = en_duration_description ? `${en_duration_description} 分` : '';

          this.s2t4album();
          this.form.primary_color = hex2Rgb(primary_color, 100);
        });
      }
    },
    'xForm.primary_color': {
      deep: true,
      handler(val) {
        this.xForm.video_cover_primary_color = val;
      },
    },
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    freshMark: {
      get() {
        return Boolean(this.freshUntil);
      },
      set(boolean) {
        if (boolean) {
          this.freshUntil = +new Date();
          const today = this.$moment();
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.freshUntil = +today;
        } else {
          this.freshUntil = 0;
        }
      },
    },
    freshUntil: {
      get() {
        return this.xForm.fresh_until * 1000;
      },
      set(val) {
        this.xForm.fresh_until = parseInt(val / 1000);
      },
    },
    triallLock: {
      get() {
        return !(this.xForm.trial_started_local_time.length || this.xForm.trial_ended_local_time.length);
      },
      set(val) {
        if (!val) {
          const today = this.$moment();
          today.set('hour', 0);
          today.set('minute', 0);
          today.set('second', 0);
          this.xForm.trial_started_local_time = today.format('YYYY-MM-DD[T]HH:mm:ss');
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.xForm.trial_ended_local_time = today.format('YYYY-MM-DD[T]HH:mm:ss');
        } else {
          this.xForm.trial_started_local_time = '';
          this.xForm.trial_ended_local_time = '';
        }
      },
    },
    trialStartedAt: {
      get() {
        return +this.$moment(this.xForm.trial_started_local_time);
      },
      set(val) {
        this.xForm.trial_started_local_time = this.$moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      },
    },
    trialEndedAt: {
      get() {
        return +this.$moment(this.xForm.trial_ended_local_time);
      },
      set(val) {
        this.xForm.trial_ended_local_time = this.$moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      },
    },
    activeSection: {
      get() {
        console.log('change: activeSection', this.xForm.sections.length, this.activeSectionIndex, this.dialogDisplay.editSection);
        if (!this.xForm.sections.length) {
          return null;
        }
        const section = this.xForm.sections[this.activeSectionIndex];
        if (!section.cognitions) {
          section.cognitions = [];
        }
        return section;
      },
      set(val) {
        this.xForm.sections[this.activeSectionIndex] = val;
      },
    },
    activeResource: {
      get() {
        console.log('change: activeResource');
        if (this.activeSection && this.activeSection.resources_v2.length) {
          const resource = this.activeSection.resources_v2.find((resource) => resource.guid === this.activeResourceGuid);
          return resource;
        }
        return null;
      },
      set(val) {
        if (this.activeSection && this.activeSection.resources_v2.length) {
          const resourceIndex = this.activeSection.resources_v2.findIndex((resource) => resource.guid === this.activeResourceGuid);
          if (~resourceIndex) {
            this.activeSection.resources_v2[resourceIndex] = Object.assign(this.activeSection.resources_v2[resourceIndex], val);
          } else {
            this.activeSection.resources_v2 = [val];
          }
        }
      },
    },
    activeCognitionResource: {
      get() {
        console.log('change: activeCognitionResource');
        if (this.activeSection && this.activeSection.cognitions) {
          const resource = this.activeSection.cognitions.find((resource) => resource.guid === this.activeCognitionResourceGuid);
          return resource;
        }
        return null;
      },
      set(val) {
        if (this.activeSection && this.activeSection.cognitions) {
          const resourceIndex = this.activeSection.cognitions.findIndex((resource) => resource.guid === this.activeCognitionResourceGuid);
          if (~resourceIndex) {
            this.activeSection.cognitions[resourceIndex] = Object.assign(this.activeSection.cognitions[resourceIndex], val);
          } else {
            this.activeSection.cognitions = [val];
          }
        }
      },
    },
  },
  methods: {
    save() {
      this.$emit('save', this.form);
    },
    async fetchTags() {
      this.objectTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type: 'meditation' },
      });
      this.objectTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchSimpleTags() {
      this.simpleTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/simple_tags',
        params: { offset: 0, limit: 512 },
      });
      this.simpleTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchSkills() {
      this.skillOptions = [];
      const res = await this.$request({
        url: '/v1/admin/meditation/skills',
      });
      this.skillOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchAlbums() {
      this.albumOptions = [];
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: {
          offset: 0,
          limit: 1000,
        },
      });
      this.albumOptions = res.data
        .filter((v) => v.id !== this.form.id)
        .map((tag) => {
          return {
            value: tag,
            label: tag.name,
          };
        });
    },
    async fetchArticles() {
      this.articleOptions = [];
      const res = await this.$request({
        url: '/v1/admin/meditation/articles',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      this.articleOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchAnnotations() {
      this.annotationOptions = [];
      const res = await this.$request({
        url: '/v2/admin/search_annotations',
      });
      this.annotationOptions = res.data.map((ann) => {
        return {
          value: ann,
          label: ann.name,
        };
      });
    },
    async fetchScenes() {
      this.sceneOptions = [];
      const res = await this.$request({
        url: '/v1/admin/scenes',
        params: { category: '', offset: 0, limit: 999 },
      });
      this.sceneOptions = res.data
        .filter((item) => item.category == 'normal' || item.category == 'meditation')
        .map((tag) => {
          return {
            value: tag,
            label: tag.name,
          };
        });
    },
    async s2t4album() {
      try {
        this.xForm.name['zh-Hant'] = this.opencc_twp(this.xForm.name['zh-Hans']);
        this.xForm.description['zh-Hant'] = this.opencc_twp(this.xForm.description['zh-Hans']);
        this.xForm.duration_description['zh-Hant'] = this.opencc_twp(this.xForm.duration_description['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },

    async s2t4section() {
      try {
        this.activeSection.name['zh-Hant'] = this.opencc_twp(this.activeSection.name['zh-Hans']);
        this.activeSection.description['zh-Hant'] = this.opencc_twp(this.activeSection.description['zh-Hans']);
        this.activeSection.duration_description['zh-Hant'] = this.opencc_twp(this.activeSection.duration_description['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },

    async freshSpeakerGroups() {
      const res = await this.$request({
        url: '/v1/admin/participants',
      });
      this.allParticipants = res.data;
      this.allSpeakers = res.data.filter((item) => item.role === 'speaker');
    },
    getParticiInfo(id) {
      for (let v of this.allParticipants) {
        if (v.id === id) {
          return v;
        }
      }
      return { name_i18n: {} };
    },

    onSelectSpeaker(row) {
      if (this.dialogDisplay.editCognitionResource) {
        this.activeCognitionResource.speaker_id = row.id;
        this.activeCognitionResource.language = this.getParticiInfo(this.activeCognitionResource.speaker_id).language;
      } else if (this.dialogDisplay.editResource) {
        this.activeResource.speaker_id = row.id;
      }
      this.dialogDisplay.chooseSpeaker = false;
    },
    showChooseSpeaker() {
      this.dialogDisplay.chooseSpeaker = true;
    },
    meditationCoverVideoHashChanged(data) {
      const { hash, key } = data;
      if (hash === '' || key === '') {
        this.xForm.video_cover_key = '';
        this.xForm.video_cover_demo_url = '';
        this.xForm.image = '';
        return;
      }
      // 加密视频
      this.xForm.video_cover_key = key;
      // demo 视频和视频封面
      this.xForm.video_cover_demo_url = `${Constant.QINIU_OPEN_CDN_DOMAIN}/${this.resourceFolder}/${hash}/demo`;
      this.xForm.image = `${Constant.QINIU_OPEN_CDN_DOMAIN}/${this.resourceFolder}/${hash}/shots`;
    },
    meditationCoverVideoUploadPersistentOps() {
      let ops_list = [];
      // 相当于复制
      let fops = 'avthumb/mp4';
      let saveas_key = qiniuUrlsafeBase64Encode(`${Constant.QINIU_OPEN_BUCKET}:${this.resourceFolder}/$(etag)/demo`);
      fops = fops + '|saveas/' + saveas_key;
      ops_list.push(fops);

      // 获取视频首帧
      fops = 'vframe/png/offset/0';
      saveas_key = qiniuUrlsafeBase64Encode(`${Constant.QINIU_OPEN_BUCKET}:${this.resourceFolder}/$(etag)/shots`);
      fops = fops + '|saveas/' + saveas_key;
      ops_list.push(fops);

      let result = ops_list.join(';');
      console.log(result);
      return result;
    },

    addSection() {
      this.xForm.sections.unshift({
        name: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        description: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        duration_description: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        resources_v2: [],
        cognitions: [],
        access: 'free',
        speaker_groups: null,
      });
      this.activeSectionIndex = 0;
      this.activeSectionActiveLang = 'en';
      this.dialogDisplay.editSection = true;
    },
    editSection(index) {
      this.activeSectionIndex = index;
      this.activeSectionActiveLang = 'en';
      this.dialogDisplay.editSection = true;
    },
    removeSection(index) {
      this.xForm.sections.splice(index, 1);
    },

    addResource() {
      const guid = uuidv4();
      if (!this.activeSection.resources_v2) {
        this.activeSection.resources_v2 = [];
      }
      this.activeSection.resources_v2.splice(0, 0, {
        guid: guid,
        name: '请修改这里的名称',
        tail_has_mute_option: true,
        tail_scene_id: '',
        tail_recommend_scene_ids: [],
        is_default_duration: false,
        duration: 0,
        hash: '',
        hash_key: '',
        human_voice_hash: '',
        human_voice_hash_key: '',
        speaker_id: '',
        has_human_voice: true,
        has_white_noise: false,
        status: 'reviewing',
      });
      console.log(this.activeSection.resources_v2);
      this.dialogDisplay.activeSectionKeyFlag += 1;
    },

    editResource(guid) {
      this.activeResourceGuid = guid;
      this.dialogDisplay.editResource = true;
      this.dialogDisplay.activeSectionKeyFlag += 1;
    },

    deleteResource(guid) {
      const resources = this.activeSection.resources_v2;
      this.activeSection.resources_v2 = resources.filter((resource) => resource.guid !== guid);
      this.activeResourceGuid = '';
      this.dialogDisplay.activeSectionKeyFlag += 1;
    },

    resourceHashChanged(data) {
      const { hash, key } = data;
      console.log('resourceHashChanged', hash, key);
      this.activeResource.hash = hash;
      this.activeResource.hash_key = key;
    },

    resourceHumanHashChanged(data) {
      const { hash, key } = data;
      console.log('resourceHumanHashChanged', hash, key);
      this.activeResource.human_voice_hash = hash;
      this.activeResource.human_voice_hash_key = key;
    },

    editCognitionResource(guid) {
      this.activeCognitionResourceGuid = guid;
      this.dialogDisplay.editCognitionResource = true;
    },
    deleteCognitionResource(guid) {
      const resources = this.activeSection.cognitions;
      this.activeSection.cognitions = resources.filter((resource) => resource.guid !== guid);
      this.activeCognitionResourceGuid = '';
      this.dialogDisplay.activeSectionCognitionsKeyFlag += 1;
    },

    addCognitionResource() {
      const guid = uuidv4();
      if (!this.activeSection.cognitions) {
        this.activeSection.cognitions = [];
      }
      this.activeSection.cognitions.splice(0, 0, {
        guid: guid,
        name: '请修改这里的名称',
        duration: 0,
        language: '',
        audio_url: '',
        video_url: '',
        cover_url: '',
        status: 'reviewing',
      });
      console.log(this.activeSection.cognitions);
      this.dialogDisplay.activeSectionCognitionsKeyFlag += 1;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
  },
  created: async function () {
    this.freshSpeakerGroups();
    this.fetchTags();
    this.fetchSimpleTags();
    this.fetchSkills();
    this.fetchAlbums();
    this.fetchArticles();
    this.fetchAnnotations();
    this.fetchScenes();
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.uploadIcon {
  display: inline-block;
}

hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.album-duration .el-input {
  width: 160px;
}

.checkbox-group-regions {
  display: inline-block;
  margin-right: 16px;
}

.input-new-tag,
.button-new-tag {
  width: 90px;
}

.form-item-label {
  position: relative;
  z-index: 2;
}

.icon-help {
  font-size: 16px;
  vertical-align: sub;
}

.resources-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
}

.resources-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding: 16px 0;
}

.resources-content {
  margin: 0 16px;
  width: auto;
}

.resources .el-tag {
  cursor: pointer;
}

.lang-span + .lang-span {
  &::before {
    content: ', ';
  }
}

.symmetry-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 32px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
}

.speaker {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.speaker.selected {
  border: 1px solid rgba(103, 194, 58, 0.2);
  background-color: rgba(103, 194, 58, 0.1);
}

.speaker + .speaker {
  margin-left: 12px;
}

.edit-speaker-btn {
  position: absolute;
  right: 20px;
  top: 0;
  display: none;
  font-size: 16px;
}

.speaker:hover {
  .edit-speaker-btn,
  .del-speaker-btn {
    display: block;
  }
}

.del-speaker-btn {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  font-size: 16px;
}

.speaker-avatar {
  width: 32px;
  height: 36px;
}

.speaker-name {
  margin-top: 8px;
  font-size: 13px;
  width: 72px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.mini-speaker {
  margin-right: 4px;
}

.speaker-groups {
  margin-top: 16px;
}

.speaker-group {
  padding: 8px 16px;
}

.speaker-group + .speaker-group {
  border-top: 1px solid rgba(0, 0, 0, 0.04);
}

.speaker-group-header {
  margin-bottom: 12px;
}

.speaker-groups {
  background-color: rgba(0, 0, 0, 0.04);
}

.speaker-group-title {
  padding: 8px 8px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 8px;
}

.speakers-container {
  padding: 16px 24px;
}
</style>
